import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import { dateDisplay } from "./../shared/utils/dateDisplay";

const ArticleTitle = ({ date, title, className, titleClassName }) => {
  return (
    <>
      <div className="flex flex-row">
        <span className={clsx(className)}>
          Opublikowano: {dateDisplay(date)}
        </span>
      </div>
      <h1 className={clsx(titleClassName)}>{title}</h1>
    </>
  );
};

export default ArticleTitle;

ArticleTitle.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string.isRequired,
};
