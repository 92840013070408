import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { removeDomElement } from "../shared/utils/removeDomElement";

const buttonStyle =
  "mx-2 md:mx-0 my-2 max-h-12 duration-300 ease-in-out transform hover:-translate-y-2 lg:hover:-translate-x-2 lg:hover:-translate-y-0 hover:scale-110";
const iconStyle = { height: "80%", width: "80%" };

const SocialMediaBar = ({ articleUrl, quote, title }) => {
  return (
    <div className="-right-8 my-4 flex flex-row items-center justify-around bg-white p-4 duration-300 lg:fixed lg:w-16 lg:flex-col lg:rounded-lg lg:opacity-50 lg:hover:-translate-x-6 lg:hover:opacity-100 lg:hover:shadow-md">
      <FacebookShareButton
        className={clsx(buttonStyle)}
        url={articleUrl}
        quote={removeDomElement(quote, 150)}
      >
        <FacebookIcon style={iconStyle} className="rounded-md" />
      </FacebookShareButton>
      <TwitterShareButton
        className={clsx(buttonStyle)}
        title={title}
        via={"Schronisko_Tychy"}
        hashtags={["Schronisko_Tychy"]}
        url={articleUrl}
      >
        <TwitterIcon style={iconStyle} className="rounded-md" />
      </TwitterShareButton>
      <LinkedinShareButton
        className={clsx(buttonStyle)}
        title={title}
        summary={`Miejskie Schronisko - Tychy: ${title}`}
        source={articleUrl}
        url={articleUrl}
      >
        <LinkedinIcon style={iconStyle} className="rounded-md" />
      </LinkedinShareButton>
      <WhatsappShareButton
        className={clsx(buttonStyle)}
        url={articleUrl}
        title={title}
      >
        <WhatsappIcon style={iconStyle} className="rounded-md" />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialMediaBar;

SocialMediaBar.propTypes = {
  title: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  articleUrl: PropTypes.string,
};
