import React, { useEffect } from "react";

import { navigate } from "gatsby";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import clsx from "clsx";

import ArticleSection from "../components/ArticleSection";
import ArticleTitle from "../components/ArticleTitle";
import LastArticleCard from "../components/LastArticleCard";
import Layout from "../components/Layout";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import SocialMediaBar from "../components/SocialMediaBar";
import useTheme from "../hook/useTheme";
import { createPageTitle } from "../shared/utils/seo";
import { createNewsDetailsUrl } from "../shared/utils/urls";

const NewsDetails = ({ data, pageContext, other }) => {
  const theme = useTheme(2);

  const articleData = pageContext.data;

  const articlePhoto = getImage(articleData.main_photo?.imageFile);
  const currentArticleID = pageContext.data?.id;

  const datePublished = new Date(articleData.date_published);
  const isAvailable = datePublished <= new Date();

  useEffect(() => {
    if (!isAvailable) {
      navigate("/404/");
    }
  }, [isAvailable]);

  // TODO: fix displaying lastArticles
  const lastArticles = (data?.directus?.article ?? [])
    // TODO: handle date_published
    .filter(({ id }) => id !== currentArticleID)
    .slice(0, 3);

  if (!isAvailable) {
    return null;
  }

  return (
    <Layout theme={theme}>
      <SEO
        title={createPageTitle(articleData.title)}
        description={articleData.contentText}
        urlPath={createNewsDetailsUrl(articleData.id)}
        imagePath={articleData.main_photo?.imageFile?.previewImage?.resize?.src}
      />
      <div className="px-2 pt-8 md:px-8 xl:px-16">
        <SectionContainer
          className={clsx("mx-auto rounded-t-md px-3 py-5", theme.bgStyleLight)}
        >
          <div className="flex w-full flex-col items-center bg-white">
            <div className="w-full rounded-t-md bg-white p-5 lg:w-2/3">
              {/* titleSection  */}
              <div className="flex flex-col font-light">
                <ArticleTitle
                  date={articleData.date_published ?? articleData.date_created}
                  title={articleData.title}
                  titleClassName="py-4 text-5xl font-bold"
                />
                {/* ARTICLE */}
                <ArticleSection
                  className={theme}
                  content={articleData.content}
                  gatsbyImage={articlePhoto}
                  gatsbyImageAlt={articleData.main_photo?.description ?? ""}
                />
              </div>
            </div>
          </div>
          <h3
            className={clsx(
              "block w-full border-b-2 p-4 text-3xl font-semibold text-white lg:hidden",
              theme.border
            )}
          >
            Udostępnij
          </h3>
          <SocialMediaBar
            articleUrl={`https://schronisko.tychy.pl/aktualnosci/${articleData.id}`}
            title={articleData.title}
            quote={articleData.content}
          />
          {/* LAST ARTICLES */}
          {lastArticles.length > 0 && (
            <div className="pt-6">
              <h3
                className={clsx(
                  "w-full border-b-2 p-4 text-3xl font-semibold text-white",
                  theme.border
                )}
              >
                Ostatnie artykuły
              </h3>
              <div className="grid grid-cols-1 gap-5 px-4 py-6 lg:grid-cols-3">
                {lastArticles.map((articleData) => (
                  <Link
                    key={articleData.id}
                    to={createNewsDetailsUrl(articleData.id)}
                  >
                    <LastArticleCard articlesData={articleData} />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </SectionContainer>
      </div>
    </Layout>
  );
};

export default NewsDetails;
