import React from "react";

import ArticleTitle from "../components/ArticleTitle";

const LastArticleCard = (props) => {
  const { date_created, title } = props.articlesData;

  return (
    <div className="cursor-pointer rounded-sm bg-white p-5 duration-200 ease-in-out hover:scale-95">
      <ArticleTitle
        date_created={date_created}
        title={title}
        titleClassName="py-4 text-xl font-bold"
        className="text-xs"
      />
    </div>
  );
};

export default LastArticleCard;
