import React from "react";
import PropTypes from "prop-types";

import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";

import clsx from "clsx";

type Props = {
  title?: string;
  content: string;
  theme: Record<string, string>;
  gatsbyImage?: GatsbyImageProps["image"];
  gatsbyImageAlt: GatsbyImageProps["alt"];
};

const ArticleSection = ({
  title,
  gatsbyImage,
  gatsbyImageAlt,
  content,
  theme,
}: Props) => {
  return (
    <>
      {gatsbyImage && (
        <GatsbyImage
          className="aspect-square h-auto w-full rounded-sm bg-cover bg-no-repeat md:aspect-[16/10]"
          image={gatsbyImage}
          alt={gatsbyImageAlt}
        />
      )}
      {title && (
        <h3
          className={clsx(
            "w-full border-b-2 pb-4 pt-6 text-xl font-semibold",
            theme.border
          )}
        >
          {title}
        </h3>
      )}
      <div
        className="prose max-w-none py-4 leading-loose"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </>
  );
};

export default ArticleSection;

ArticleSection.propTypes = {
  title: PropTypes.string,
  gatsbyImage: PropTypes.object,
  gatsbyImageAlt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  theme: PropTypes.object,
};
