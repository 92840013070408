import { clipLongText } from "./text";

//loop for search indexes
const searchSign = (array, sign, content, index) => {
  for (const string of content) {
    if (string === sign) {
      array.push(index);
    }
    index++;
  }
};

export const removeDomElement = (content, range) => {
  //initialise array for indexs
  let stringsArrGreaterThanSign = [];
  let stringsArrLessThanSign = [];

  //searches and remove first and last elements of DOM
  const indexGreaterThanSign = content.indexOf(">");
  const indexLessThanSign = content.lastIndexOf("<");
  const filteredContent = content.slice(
    indexGreaterThanSign + 1,
    indexLessThanSign - 1
  );

  //clipping content to maximum set value
  const clipedFilteredText =
    filteredContent.length > range
      ? clipLongText(filteredContent, range)
      : filteredContent;

  //initialise start index for special signs
  let indexOfGreaterThanSign = 0;
  let indexOfLessThanSign = 0;

  //search all signs in content and return indexs
  searchSign(
    stringsArrGreaterThanSign,
    ">",
    clipedFilteredText,
    indexOfGreaterThanSign
  );

  searchSign(
    stringsArrLessThanSign,
    "<",
    clipedFilteredText,
    indexOfLessThanSign
  );

  const serchedStringsArray = [];

  let currentFirstArrayIndex = 0;

  //search all DOM elements in content
  stringsArrLessThanSign.map((firstArrayNumber) => {
    serchedStringsArray.push(
      clipedFilteredText.substring(
        firstArrayNumber,
        stringsArrGreaterThanSign[currentFirstArrayIndex] + 1
      )
    );
    return currentFirstArrayIndex++;
  });

  //remove all DOM elements from text
  let fullyDOMElementsRemovedText = clipedFilteredText;

  serchedStringsArray.forEach((word) => {
    fullyDOMElementsRemovedText = fullyDOMElementsRemovedText.replace(word, "");
  });

  return fullyDOMElementsRemovedText;
};
